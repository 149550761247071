.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
svg {
  shape-rendering: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  position: relative;
  overflow: hidden;
  line-height: 40px;
  color: #ecf0f1;
}
.content::before {
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  z-index: 1;
  display: block;
  content: "";
}
.content::after {
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  bottom: -1px;
  left: 0;
  height: 5px;
  width: 100%;
  z-index: 1;
  display: block;
  content: "";
}
.content__container {
  overflow: hidden;
  height: 45px;
}
.content__container__text {
  margin: 0;
}

.content__container__list {
  margin-top: 0;
  text-align: left;
  list-style: none;
  padding: 0;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  padding-bottom: 3px;
}
.content__container__list__item {
  line-height: 25px;
  margin: 0;
}
@media (min-width: 768px) {
  .content__container__list__item {
    line-height: 40px;
    margin: 0;
  }
  .content__container {
    overflow: hidden;
    height: 60px;
  }
}
.content__container__list__item#first {
  color: #b96f55;
}
.content__container__list__item#second {
  color: #918fbf;
}
.content__container__list__item#third {
  color: #29b986;
}
.content__container__list__item#fourth {
  color: #e6cd86;
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0% {
    transform: translate3d(0, 15%, 0);
    opacity: 0.3;
  }
  5.32%, 28%{
    transform: translate3d(0, -3%, 0);
    opacity: 1;
  }
  33.66%,
  45.32% {
    transform: translate3d(0, -28%, 0);
  }
  55.32%,
  70.98% {
    transform: translate3d(0, -53%, 0);
  }
  74.98%,
  95.34% {
    transform: translate3d(0, -78%, 0);
  }
  97.5%,
  100% {
    transform: translate3d(0, -100%, 0);
    opacity: 0.8;
  }
}
@keyframes Phones {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
    transform: rotate(15deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    transform: rotate(0deg);
  }
}
#phone_3 {
  opacity: 0;
}
#phone_2 {
  opacity: 0;
}
#phone_1 {
  opacity: 0;
}
#phonesvg.active #phone_3 {
  animation: Phones 0.6s 0.5s ease-in-out 1 alternate both;
}
#phonesvg.active #phone_2 {
  animation: Phones 0.6s 0.7s ease-in-out 1 alternate both;
}
#phonesvg.active #phone_1 {
  animation: Phones 0.6s 1s ease-in-out 1 alternate both;
}
#about::before{
  position: absolute;
  top:-90px;
  left:0;
  width: 100%;
  z-index: 0;
  content: "";
  height: 150px;
  background-image: url("./resources/pages/landingpage/images/divider2-white.svg");
}

#values::before{
  position: absolute;
  top:-90px;
  left:0;
  width: 100%;
  z-index: -1;
  content: "";
  height: 150px;
  background-image: url("./resources/pages/landingpage/images/divider-grad.svg");
}
#tecnica::before{
  position: absolute;
  top:-90px;
  left:0;
  width: 100%;
  z-index: -1;
  content: "";
  height: 150px;
  background-image: url("./resources/pages/landingpage/images/divider2.svg");
}
#partners::before{
  position: absolute;
  top:-90px;
  left:0;
  width: 100%;
  z-index: 0;
  content: "";
  height: 150px;
  background-image: url("./resources/pages/landingpage/images/divider2-white.svg");
}